// Generated by Framer (018ad06)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withMappedReactProps } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Single, * as SingleInfo from "https://framerusercontent.com/modules/cBF15lBi7RiyI8q4CFpd/VDKXw1bdoJ5vGWb1hZF8/Zbmf7W7C5.js";
import { withSingleToggle } from "https://framerusercontent.com/modules/qI1autnce1ngcX7nJQbN/F8GOjVGSpZOM9Vfv4HZW/SingleToggle.js";
const SingleFonts = getFonts(Single);
const SingleWithSingleToggleWithMappedReactPropsg424ez = withMappedReactProps(withSingleToggle(Single), SingleInfo);

const cycleOrder = ["ZoXazrq4j"];

const serializationHash = "framer-8vrK1"

const variantClassNames = {ZoXazrq4j: "framer-v-12sqnj2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const numberToPixelString = (value) => {
    if (typeof value !== "number") return value;
    if (!Number.isFinite(value)) return undefined;
    return Math.max(0, value) + "px";
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, iconColor, id, padding, width, ...props}) => { return {...props, ogBQvzNNC: padding ?? props.ogBQvzNNC ?? 10, Tib6Q7ZnQ: iconColor ?? props.Tib6Q7ZnQ ?? "var(--token-fa6255fa-1747-4bc3-894a-8375bc25f0bd, rgb(28, 28, 28))"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;iconColor?: string;padding?: number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, izVnO3mC9, aoJpqCstc, Xu4nOYGGe, q3VxHPdzp, Tib6Q7ZnQ, ogBQvzNNC, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ZoXazrq4j", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-12sqnj2", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ZoXazrq4j"} ref={ref ?? ref1} style={{"--omf6xn": numberToPixelString(ogBQvzNNC), ...style}}><ComponentViewportProvider ><motion.div className={"framer-pzw0fe-container"} layoutDependency={layoutDependency} layoutId={"n_PsrrU7R-container"}><SingleWithSingleToggleWithMappedReactPropsg424ez AeO5PLd9q={Tib6Q7ZnQ} ddWa08mZD={Xu4nOYGGe} height={"100%"} id={"n_PsrrU7R"} layoutId={"n_PsrrU7R"} QNUIABbS6={q3VxHPdzp} variant={"udnmimFYL"} WfbOFlO0c={izVnO3mC9} width={"100%"} zcws0PLsu={aoJpqCstc}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-8vrK1.framer-kbmey6, .framer-8vrK1 .framer-kbmey6 { display: block; }", ".framer-8vrK1.framer-12sqnj2 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; padding: var(--omf6xn); position: relative; width: min-content; }", ".framer-8vrK1 .framer-pzw0fe-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-8vrK1.framer-12sqnj2 { gap: 0px; } .framer-8vrK1.framer-12sqnj2 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-8vrK1.framer-12sqnj2 > :first-child { margin-left: 0px; } .framer-8vrK1.framer-12sqnj2 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"Tib6Q7ZnQ":"iconColor","ogBQvzNNC":"padding"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerjtn_sz6F3: React.ComponentType<Props> = withCSS(Component, css, "framer-8vrK1") as typeof Component;
export default Framerjtn_sz6F3;

Framerjtn_sz6F3.displayName = "SingleToggle";

Framerjtn_sz6F3.defaultProps = {height: 40, width: 40};

addPropertyControls(Framerjtn_sz6F3, {Tib6Q7ZnQ: {defaultValue: "var(--token-fa6255fa-1747-4bc3-894a-8375bc25f0bd, rgb(28, 28, 28)) /* {\"name\":\"font/active\"} */", title: "IconColor", type: ControlType.Color}, ogBQvzNNC: {defaultValue: 10, displayStepper: true, min: 0, title: "Padding", type: ControlType.Number}} as any)

addFonts(Framerjtn_sz6F3, [{explicitInter: true, fonts: []}, ...SingleFonts], {supportsExplicitInterCodegen: true})